<template>
  <div class="voluteer-container" v-loading="loading">
    <!-- <div class="voluteer-crumbs">
      <router-link :to="{ name: 'culture_homepage' }">首页 / </router-link>
      <div>志愿服务</div>
    </div> -->
    <div class="voluteer-info">
      <div class="tab-secondNav" v-if="secondNav.length > 0" style="margin-bottom:0.2rem;">
        <ul class="tab-secondNav-choose-item">
          <li
            v-for="(item, i) in secondNav"
            :key="i"
            :class="{ tabActivesecondNav: cur2 == item.id }"
            @click="changeSecondNav(item, i)"
          >
            {{ item.name }}
            <span :class="{ tabActivesecondNavSpan: cur2 == item.id }">
              <span :class="{ tabActivesecondNavSubSpan: cur2 == item.id }">
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div class="culture-switch-type" v-if="categoryList&&categoryList.length>0">
        <div class="culturek-switch-type-title"></div>
        <div class="culture-switch-type-content">
          <div
            class="culture-switch-type-content-li"
            :class="{
              'culture-switch-type-content-li-active': cur == item.id,
              imgType: item.label_name.length > 4,
            }"
            v-for="(item, index) in categoryList"
            :key="'categoryList' + index"
            @click="handleChangeValue(item.id)"
          >
            {{ item.label_name }}
          </div>
        </div>
      </div>
      <div>
        <ul class="volunteer-ul">
          <li
            class="volunteer-ul-li"
            v-for="(item, index) in rows"
            :key="'volunteer' + index"
            @click="toNewPage(item)"
          >
            <div class="volunteer-ul-li-banner">
              <img style="width: 100%; height: 100%" :src="item.cover" alt="" />
              <div class="volunteer-ul-li-banner-total">
                {{ item.team_total }}人
              </div>
            </div>
            <div class="volunteer-ul-li-bottom">
              <div class="volunteer-ul-li-bottom-title">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 分页组件 -->
      <pagination
        :total="count"
        :page.sync="page"
        :limit.sync="limit"
        @pagination="getList"
        v-if="rows.length > 0"
      />
      <Nocontent v-else title="暂无艺术培训，请持续关注哦～"></Nocontent>
    </div>
  </div>
</template>

<script>
import { voluteerList } from "@/api/common";
import Pagination from "@/components/Pagination";
import Nocontent from "@/views/components/Nocontent";
import { navChildList } from "@/api/header/header";
export default {
  data() {
    return {
      rows: [],
      aid: process.env.VUE_APP_AID,
      page: 1,
      limit: 6,
      loading: true,
      column_id: "",
      cid: "",
      cur2: 0,
      cur: 0,
      count: 0,
      categoryList: [],
      secondNav: [],
    };
  },
  components: {
    Pagination,
    Nocontent,
  },
  mounted() {
    window.scroll(0, 0);
    this.column_id = this.$route.query.column_id;
    this.cid = this.$route.query.cid;
    if (this.cid) {
      this.cur2 = this.cid;
    }
    this.getList();
    this.getChildNav();
  },
  watch:{
    $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.categoryList = [];
      this.getList();
      this.getChildNav();
    },
  },
  methods: {
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };
      navChildList(params).then((res) => {
        if (res.code == 0) {
          this.secondNav = res.data;
        }
      });
    },
    changeSecondNav(item) {
      //获取导航下的分类
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
          this.$router.push(
                `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}`,
          //   query: {
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        } else {
          this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}/detail`,
          //   query: {
          //     tid: item.content_id,
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        }
      }
    },
    handleChangeValue(i) {
      this.page = 1;
      this.cur = i;
      this.getList();
    },
    getList() {
      let params = {
        aid: this.aid,
        page: this.page,
        page_size: this.limit,
        column_id: this.column_id,
      };
      if (parseInt(this.cur) > 0) {
        params.label_id = parseInt(this.cur);
      }
      voluteerList(params).then((res) => {
        if (res.code == 0) {
          this.rows = res.data.datalist;
          this.count = res.data.total;
          if (res.data.label_list.length > 0) {
            this.categoryList = res.data.label_list;
            this.categoryList.unshift({
              id: 0,
              label_name: "全部",
            });
          }
          this.loading = false;
        }
      });
    },
    toNewPage(item) {
      if (item.is_link == 1 && item.jump_url) {
        window.open(item.jump_url, "_blank");

      } else {
        let routeUrl = this.$router.resolve({
          path: `/volunteerTeam-default/detail`,
          query: { tid:item.id,id:this.$route.query.id,cid:this.$route.query.cid,column_id:this.$route.query.column_id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/volunteerTeam_default_index.scss";
</style>
